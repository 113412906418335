<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <MatriculaDRETextField v-model="inscricaoREIN.matricula" :label="'Matrícula do Curso Anterior'"/>
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import MatriculaDRETextField from "@/components/pages/Inscricao/MatriculaDRETextField";

export default {
  name: "InscricaoFormREIN",

  components: {
    EscolhaOpcaoSelect,
    MatriculaDRETextField
  },

  props: {
    processo: { type: Object, required: true },
    inscricao: { type: Object, required: true }
  },

  data() {
    return {
      inscricaoREIN: {
        inscricao: null,
        matricula: "",
      }
    };
  },

  watch: {
    inscricaoREIN: {
      handler(){
        this.inscricaoREIN.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoREIN);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
};
</script>
